import { graphql } from "gatsby";
import { isBrowser } from "../utils/browserUtils";
import {OurCustomers} from "./our-customers"

if (isBrowser) {
  localStorage.setItem('access', 'true')
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: {
          in: ["our-customers", "testimonials", "common", "navbar", "footer", "getQuoteModal"]
        }, 
        language: {eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default OurCustomers